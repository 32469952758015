//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {

}

nav {
	.navbar-brand svg {
		height: 24px !important;
		width: auto;
		margin-bottom: 2px;
	}
}

main {
	min-height: 90vh;
}

section.xl-title {
	position: relative;
	&:before {
		content: ' ';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0.3;
		background-image: url("../images/backgrounds/hero_panorama_background-1920x1080.jpeg");
		background-repeat: no-repeat;
		background-position: 50% 0;
		background-size: cover;
	}
	.card {
		color: #fff;
	}
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";
